import {IconButton, type IconButtonProps, useModal} from '@gicortex/nucleus'
import {Plus} from 'lucide-react'

export interface CreateButtonProps
  extends Omit<IconButtonProps, 'icon' | 'onClick'> {
  modal: React.ReactNode
  singularEntityName: string
}
export const CreateButton = ({
  modal,
  singularEntityName,
}: CreateButtonProps) => {
  const {openModal} = useModal()

  const handleClick = () => {
    openModal(modal)
  }

  return (
    <IconButton
      onClick={handleClick}
      icon={Plus}
      tooltip={`Create ${singularEntityName}`}
    />
  )
}
