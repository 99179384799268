import {Row, cn} from '@gicortex/nucleus'

export interface ButtonGroupProps {
  children: React.ReactNode
  className?: string
}

export const ButtonGroup = ({children, className}: ButtonGroupProps) => {
  return <Row className={cn('ml-auto', className)}>{children}</Row>
}
