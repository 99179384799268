import {authConfig} from '@/configs'
import {authUtil} from '@/utils'
import {useEffect, useState} from 'react'
import {Navigate, useSearchParams} from 'react-router-dom'

export const CallbackPage = () => {
  const [searchParams] = useSearchParams()
  const [hasTokens, setHasTokens] = useState(false)

  useEffect(() => {
    const accessToken = searchParams.get(authConfig.accessTokenCacheKey)
    const refreshToken = searchParams.get(authConfig.refreshTokenCacheKey)
    if (accessToken && refreshToken) {
      authUtil.setTokens({accessToken, refreshToken})
      setHasTokens(true)
    }
  }, [searchParams])

  return hasTokens ? <Navigate to='/' /> : null
}
