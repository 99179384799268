import {useDeletePromptByIdMutation} from '@/api'
import {useRequest} from '@/hooks/request'
import type {Prompt} from '@/types'
import {fileUtil} from '@/utils'
import {DeleteModalButton} from '@gicortex/nucleus'
const ACTION_TITLE = 'Delete Prompt'

export interface DeletePromptButtonProps {
  prompt: Prompt
}

export const DeletePromptButton = ({prompt}: DeletePromptButtonProps) => {
  const [deletePrompt] = useDeletePromptByIdMutation()
  const {handleMutation} = useRequest()
  const handleDelete = async () => {
    return handleMutation({
      notficationProps: {
        entityName: 'prompt',
        action: 'delete',
      },
      operation: deletePrompt({id: prompt.id}),
    })
  }

  const promptPath = fileUtil.getFullPath(prompt) + prompt.name

  return (
    <DeleteModalButton
      className='hidden p-0 group-hover:block'
      content={
        <p>
          Are you sure you want to delete <strong>{promptPath}</strong>?
        </p>
      }
      title={ACTION_TITLE}
      tooltip={ACTION_TITLE}
      onSubmit={handleDelete}
    />
  )
}
