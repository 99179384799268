import {authUtil} from '@/utils'
import {Path} from '@gicortex/nucleus'
import {Navigate, Route, Routes} from 'react-router-dom'
import {AppPage} from './app-page'
import {CallbackPage} from './callback-page'
import {LoginPage} from './login-page'
import {LogoutPage} from './logout-page'

const AUTH_PATHS = [
  {path: Path.ROOT, page: AppPage},
  {path: Path.LOGOUT, page: LogoutPage},
]

export const AuthRoute = ({
  page: Page,
}: {
  page: React.ComponentType
}) => {
  const isAuthenticated = authUtil.getTokens().accessToken
  return isAuthenticated ? <Page /> : <Navigate to={Path.LOGIN} />
}

export const Router = () => (
  <Routes>
    <Route
      element={<LoginPage />}
      path={Path.LOGIN}
    />
    <Route
      element={<CallbackPage />}
      path={Path.CALLBACK}
    />
    {AUTH_PATHS.map(({path, page}) => (
      <Route
        element={<AuthRoute page={page} />}
        key={path}
        path={path}
      />
    ))}
    <Route
      element={<Navigate to={Path.LOGIN} />}
      path='*'
    />
  </Routes>
)
