import type {ChatResponse} from '@/api'
import {Row} from '@gicortex/nucleus'

export const UserMessage = ({message}: {message: ChatResponse}) => (
  <Row className='justify-end'>
    <Row className='bg-indigo-50 p-4 rounded-2xl w-4/6 whitespace-pre-wrap'>
      {message.message}
    </Row>
  </Row>
)
