import {api} from '@/api'
import {IconButton, KvLogo, Layout, PlaceCenter, Row} from '@gicortex/nucleus'
import {LogIn} from 'lucide-react'

const LoginButton = () => {
  const {data: config, isLoading, isError} = api.useGetConfigsQuery()

  const handleLogin = () => {
    if (config) {
      window.location.replace(
        `${config.serverUrl}${config.callbackPath}?origin=${window.location.origin}`,
      )
    }
  }

  return (
    <IconButton
      loading={isLoading}
      disabled={isLoading || isError}
      icon={LogIn}
      onClick={handleLogin}
      size='m'
      tooltip='Log In'
    />
  )
}

export const LoginPage = () => (
  <Layout
    footer
    showSidebar={false}
  >
    <PlaceCenter>
      <Row className='flex-wrap justify-between w-56'>
        <KvLogo />
        <LoginButton />
      </Row>
    </PlaceCenter>
  </Layout>
)
