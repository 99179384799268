import {IconButton, type IconButtonProps, useModal} from '@gicortex/nucleus'
import {FolderPlus} from 'lucide-react'

export interface CreateFolderButtonProps
  extends Omit<IconButtonProps, 'icon' | 'onClick' | 'tooltip'> {
  modal: React.ReactNode
}

export const CreateFolderButton = ({
  modal,
  ...props
}: CreateFolderButtonProps) => {
  const {openModal} = useModal()

  const handleClick = () => {
    openModal(modal)
  }

  return (
    <IconButton
      {...props}
      onClick={handleClick}
      icon={FolderPlus}
      tooltip='Create Folder'
    />
  )
}
