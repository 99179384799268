import {useGetDatabucketFilesQuery, useGetFoldersQuery} from '@/api'
import {FolderType} from '@/configs'
import {SidebarPanel} from '@/layout/sidebar-panel'
import {SharedFiles} from './shared-files'
import {YourFiles} from './your-files'

export const Files = () => {
  const {data: folders} = useGetFoldersQuery({type: FolderType.FILES})
  const {data: files} = useGetDatabucketFilesQuery()

  return (
    <SidebarPanel
      panels={[
        {
          key: 'your-files',
          title: <YourFiles.Title />,
          body: (
            <YourFiles
              folders={folders}
              files={files}
            />
          ),
        },
        {
          key: 'shared-files',
          title: <SidebarPanel.Title title='Shared Files' />,
          body: (
            <SharedFiles
              folders={folders}
              files={files}
            />
          ),
        },
      ]}
    />
  )
}
