import {type ChatFileResponse, type ChatHistoryLiteResponse, api} from '@/api'
import {SidebarPanel} from '@/layout/sidebar-panel'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {
  Col,
  DeleteModalButton,
  Row,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  cn,
  useToast,
} from '@gicortex/nucleus'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {useDispatch, useSelector} from 'react-redux'
dayjs.extend(isBetween)

const currentDate = dayjs()

const Section = ({
  chats = [],
}: {
  chats?: ChatHistoryLiteResponse[]
}) => {
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()
  const [deleteChatHistory] = api.useDeleteChatHistoryMutation()
  const [getChatHistory] = api.useLazyGetChatHistoryQuery()

  const handleClick = async (chat: ChatHistoryLiteResponse) => {
    dispatch(currentSlice.actions.setHistoryId(chat.id))
    dispatch(currentSlice.actions.clearSelectedFiles())

    const messages = await getChatHistory({id: chat.id}).unwrap()
    dispatch(currentSlice.actions.setMessages([...messages].reverse()))
    const uniqueFiles = Array.from(
      new Map(
        messages.reduce(
          (acc, msg) => {
            if (msg.files) {
              for (const file of msg.files) {
                if (file?.path) {
                  acc.push([file.path, file])
                }
              }
            }
            return acc
          },
          [] as [string, ChatFileResponse][],
        ),
      ).values(),
    )
    for (const file of uniqueFiles) {
      dispatch(currentSlice.actions.selectFile(file))
    }
  }

  interface DeleteChatModalProps {
    chat: ChatHistoryLiteResponse
  }
  const DeleteChatButton = ({chat}: DeleteChatModalProps) => {
    const {showErrorToast} = useToast()
    return (
      <DeleteModalButton
        title='Delete Chat'
        className={cn('p-0', 'hidden group-hover:block')}
        content={
          <p>
            Are you sure you want to delete <strong>{chat.name}</strong>?
          </p>
        }
        tooltip='Delete Chat'
        onSubmit={async () => {
          const {error} = await deleteChatHistory({id: chat.id})
          if (error) {
            showErrorToast('Failed to delete chat history')
            return console.error(error)
          }
          dispatch(currentSlice.actions.setMessages([]))
        }}
      />
    )
  }

  return (
    <Col className='gap-2'>
      {chats.length === 0 ? (
        <p className='text-sm p-2'>None</p>
      ) : (
        chats
          .map((chat) => (
            <Row
              className={cn(
                'group capitalize text-sm p-2 hover:bg-indigo-100 rounded-lg hover:cursor-pointer justify-between',
                chat.id === current.historyId && 'bg-gray-200',
              )}
              key={chat.id}
              onClick={() => handleClick(chat)}
            >
              <Tooltip>
                <TooltipTrigger asChild={true}>
                  <p className='truncate'>{chat.name}</p>
                </TooltipTrigger>
                <TooltipContent>{chat.name}</TooltipContent>
              </Tooltip>
              <DeleteChatButton chat={chat} />
            </Row>
          ))
          .reverse()
      )}
    </Col>
  )
}

export const History = () => {
  const {data: chats = []} = api.useGetChatHistoriesQuery()

  const today = chats.filter((chat) =>
    dayjs(chat.createdAt).isSame(currentDate, 'day'),
  )
  const yesterday = chats.filter((chat) =>
    dayjs(chat.createdAt).isSame(currentDate.subtract(1, 'day'), 'day'),
  )
  const thisWeek = chats.filter((chat) =>
    dayjs(chat.createdAt).isBetween(
      currentDate.subtract(7, 'day').startOf('day'),
      currentDate.subtract(2, 'day').endOf('day'),
      'day',
      '[]',
    ),
  )

  const thisMonth = chats.filter((chat) =>
    dayjs(chat.createdAt).isBetween(
      currentDate.subtract(30, 'day').startOf('day'),
      currentDate.subtract(7, 'day').endOf('day'),
      'day',
      '[]',
    ),
  )
  const olderThanThisMonth = chats.filter((chat) =>
    dayjs(chat.createdAt).isBefore(
      currentDate.subtract(30, 'day').startOf('day'),
    ),
  )

  return (
    <SidebarPanel
      minPanelOpenSize={100}
      panels={[
        {
          key: 'today',
          title: <SidebarPanel.Title title='Today' />,
          body: <Section chats={today} />,
        },
        {
          key: 'yesterday',
          title: <SidebarPanel.Title title='Yesterday' />,
          body: <Section chats={yesterday} />,
        },
        {
          key: 'this-week',
          defaultClosed: true,
          title: <SidebarPanel.Title title='This Week' />,
          body: <Section chats={thisWeek} />,
        },
        {
          key: 'this-month',
          defaultClosed: true,
          title: <SidebarPanel.Title title='This Month' />,
          body: <Section chats={thisMonth} />,
        },
        {
          key: 'older-than-this-month',
          defaultClosed: true,
          title: <SidebarPanel.Title title='Older than this month' />,
          body: <Section chats={olderThanThisMonth} />,
        },
      ]}
    />
  )
}
