import {
  type FolderResponseModel,
  type UpdateResourceFolderModel,
  useGetFoldersQuery,
  useUpdateResourceFolderInBatchMutation,
} from '@/api'
import type {HttpError, Prompt} from '@/types'
import {Input, Listbox, Modal, useModal, useToast} from '@gicortex/nucleus'
import {useState} from 'react'

export interface MovePromptModalProps {
  pathPrefix: string
  prompt: Prompt
}
export const MovePromptModal = ({pathPrefix, prompt}: MovePromptModalProps) => {
  const [rootFolder] = useState({
    id: pathPrefix,
    path: `${pathPrefix}/`,
  } as FolderResponseModel)
  const {data: folders = []} = useGetFoldersQuery({type: 'prompt'})

  const [moveToFolder] = useUpdateResourceFolderInBatchMutation()

  const {showSuccessToast, showErrorToast} = useToast()
  const {closeModal} = useModal()

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      const addActions: UpdateResourceFolderModel[] = []
      if (selectedFolder.id !== rootFolder.id && selectedFolder.id) {
        addActions.push({
          action: 'add',
          folderId: selectedFolder.id,
          promptId: prompt.id,
        })
      }
      const removeActions: UpdateResourceFolderModel[] =
        prompt.tags && prompt.tags?.length > 0
          ? (prompt.tags
              .filter((tag) => tag.key === 'folder')
              .map((tag) => ({
                action: 'remove',
                folderId: folders.find((folder) => folder.path === tag.value)
                  ?.id,
                promptId: prompt.id,
              }))
              .filter(
                (action) => action.folderId !== undefined,
              ) as UpdateResourceFolderModel[])
          : []
      await moveToFolder({
        body: [...addActions, ...removeActions],
      }).unwrap()
      closeModal()
      showSuccessToast('Prompt moved successfully')
    } catch (error: unknown) {
      const httpError = error as HttpError
      switch (httpError.status) {
        default:
          showErrorToast('Failed to move prompt')
      }
    } finally {
      setSubmitting(false)
    }
  }

  const options = prompt.tags?.length
    ? [
        rootFolder,
        ...folders.filter((folder) =>
          prompt.tags?.find((tag) => tag.value !== folder.path),
        ),
      ]
    : folders

  const [selectedFolder, setSelectedFolder] = useState<
    Partial<FolderResponseModel>
  >(options[0])

  return (
    <Modal>
      <Modal.Title>Move To Folder</Modal.Title>
      <Input
        defaultValue={prompt.name}
        disabled
        label='Prompt'
        name='prompt'
      />
      <Listbox
        label='Folder'
        name='folder'
        defaultSelected={options[0].id}
        optionLabelKey='path'
        optionValueKey='id'
        options={options}
        onChange={(option) => setSelectedFolder(option as FolderResponseModel)}
      />
      <Modal.Footer
        onSubmit={handleSubmit}
        submitButtonProps={{
          className: 'disabled:bg-red-100',
          disabled: submitting,
          label: 'Move',
        }}
      />
    </Modal>
  )
}
