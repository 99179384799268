import {FILE_PROCESSING, STORAGE_PROTOCOL} from '@/configs'
import type {DataBucketFile, FileProcessingStatus, Prompt} from '@/types'
import type {DataBucketFileWithRetryAttempts} from '@/types/data-bucket-file'
import {uuidUtil} from '@/utils'

const removeUuidPrefix = (fileName: string) => {
  const substrings = fileName.split('.')
  return uuidUtil.isUuid(substrings[0])
    ? substrings.slice(1).join('.')
    : fileName
}

const getStatusProps = (status: FileProcessingStatus) => {
  switch (status) {
    case FILE_PROCESSING.status.success:
      return {
        status: FILE_PROCESSING.status.success,
        color: FILE_PROCESSING.color.success,
        description: FILE_PROCESSING.description.success,
      }
    case FILE_PROCESSING.status.failure:
      return {
        status: FILE_PROCESSING.status.failure,
        color: FILE_PROCESSING.color.failure,
        description: FILE_PROCESSING.description.failure,
      }
    default:
      return {
        status: FILE_PROCESSING.status.pending,
        color: FILE_PROCESSING.color.pending,
        description: FILE_PROCESSING.description.pending,
      }
  }
}

const getName = (
  file: DataBucketFile,
  {shouldRemoveUuidPrefix = false} = {},
) => {
  const name = file.file.split('/').pop()
  if (!name) return 'untitled'
  if (shouldRemoveUuidPrefix) {
    return removeUuidPrefix(name)
  }
  return name
}

const getExtension = (file: DataBucketFile) => {
  const parts = file.file.split('.')
  return parts.length === 1 ? undefined : parts.pop()
}

const getSourceId = (file: DataBucketFile) => {
  return `${STORAGE_PROTOCOL.AWS}${file.bucket}/${file.path}`
}

const hasPollingAttemptsRemaining = (file: DataBucketFileWithRetryAttempts) => {
  const {success} = FILE_PROCESSING.status
  if (
    file.status !== success &&
    file.retryAttempts < FILE_PROCESSING.polling.maxAttempts
  ) {
    return true
  }
  return false
}

const humanReadableFileSize = (size: number) => {
  if (size < 1024) return `${size} B`
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`
  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`
}

const getFullPath = (item: DataBucketFile | Prompt) =>
  item.tags?.[0] ? `${item.tags?.[0].value}/` : ''

const shouldContinuePolling = (files: DataBucketFileWithRetryAttempts[]) => {
  const fileWithRemainingPollingAttempts = files.filter((file) =>
    hasPollingAttemptsRemaining(file),
  )
  return fileWithRemainingPollingAttempts.length > 0
}

export const fileUtil = {
  getExtension,
  getFullPath,
  getName,
  getSourceId,
  getStatusProps,
  hasPollingAttemptsRemaining,
  humanReadableFileSize,
  removeUuidPrefix,
  shouldContinuePolling,
}
