import {ButtonGroup} from '@/components/button-group'
import {MoveToFolderButton} from '@/components/move-to-folder-button'
import {SendButton, focusPrompt} from '@/components/send-prompt-button'
import {FolderType} from '@/configs'
import {useCurrentPrompt} from '@/hooks/current-prompt'
import type {Prompt} from '@/types'
import {
  Row,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  cn,
} from '@gicortex/nucleus'
import {DeletePromptButton} from './delete-prompt-button'
import {MovePromptModal} from './move-prompt-modal'

export interface PromptItemProps {
  className?: string
  pathPrefix: string
  prompt: Prompt
}
export const PromptItem = ({
  className,
  pathPrefix,
  prompt,
}: PromptItemProps) => {
  const {setPrompt} = useCurrentPrompt()

  const handleSelect = () => {
    setPrompt(prompt.prompt)
    focusPrompt()
  }

  return (
    <Row
      className={cn(
        'gap-1.5 group text-sm p-2 hover:bg-indigo-100 rounded-lg hover:cursor-pointer flex justify-between items-center',
        className,
      )}
      key={prompt.id}
      onClick={handleSelect}
    >
      <Tooltip>
        <TooltipTrigger asChild={true}>
          <p className='truncate'>{prompt.name}</p>
        </TooltipTrigger>
        <TooltipContent>{prompt.name}</TooltipContent>
      </Tooltip>
      <ButtonGroup className='align-center hidden ml-auto space-x-2 group-hover:flex [&_button]:p-0'>
        <SendButton prompt={prompt} />
        <MoveToFolderButton
          folderType={FolderType.PROMPTS}
          modal={
            <MovePromptModal
              pathPrefix={pathPrefix}
              prompt={prompt}
            />
          }
          pathPrefix={pathPrefix}
        />
        <DeletePromptButton prompt={prompt} />
      </ButtonGroup>
    </Row>
  )
}
