import {ButtonGroup} from '@/components/button-group'
import {CreateButton} from '@/components/create-button'
import {CreateFolderButton} from '@/components/create-folder-button'
import {DeleteFolderButton} from '@/components/delete-folder-button'
import {CreatePromptModal} from '@/features/prompts/create-prompt-modal'
import {CreatePromptsFolderModal} from '@/features/prompts/create-prompts-folder-modal'
import {PromptItem} from '@/features/prompts/prompt-item'
import type {Entity, Folder, Prompt} from '@/types'
import {folderUtil} from '@/utils'
import {Icon, Row, cn} from '@gicortex/nucleus'
import {ChevronDown, ChevronRight} from 'lucide-react'
import {useState} from 'react'

export interface PromptFolderProps {
  className?: string
  folder: Folder
  pathPrefix: string
}
export const PromptFolder = ({
  className,
  folder,
  pathPrefix,
}: PromptFolderProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Row
        className={cn(
          'gap-1 group ml-auto p-1 rounded-lg w-full hover:bg-indigo-100 hover:cursor-pointer',
          className,
        )}
        onClick={() => setOpen(!open)}
      >
        <Row className='flex-1 gap-1'>
          <Icon component={open ? ChevronDown : ChevronRight} />
          {folder.path?.split('/').pop()}
        </Row>
        <ButtonGroup className='align-center hidden ml-auto pr-1 space-x-2 group-hover:flex [&_button]:p-0'>
          <CreateButton
            modal={
              <CreatePromptModal
                folder={folder}
                pathPrefix={pathPrefix}
              />
            }
            singularEntityName='Prompt'
          />
          <CreateFolderButton
            modal={
              <CreatePromptsFolderModal
                folder={folder}
                pathPrefix={pathPrefix}
              />
            }
          />
          <DeleteFolderButton folder={folder} />
        </ButtonGroup>
      </Row>
      {open &&
        'children' in folder &&
        folder.children?.map((item: Folder | Entity) => (
          <div
            style={{
              // NOTE @simon No idea why `ml-${(item.indent ?? 0) + 1}`
              // doesn't work here
              marginLeft: `${(item.indent ?? 0) + 4}px`,
            }}
            key={item.id}
          >
            {folderUtil.isPromptFolder(item) ? (
              <PromptFolder
                folder={item as Folder}
                pathPrefix={pathPrefix}
              />
            ) : (
              <PromptItem
                prompt={item as Prompt}
                pathPrefix={pathPrefix}
              />
            )}
          </div>
        ))}
    </>
  )
}
