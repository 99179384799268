import {authUtil} from '@/utils'
import {Row} from '@gicortex/nucleus'

export const Footer = () => (
  <Row className='justify-center p-2 text-gray-400 text-xs'>
    {authUtil.getTokens().accessToken ? (
      <>AI can make mistakes. Check important info.</>
    ) : (
      <>
        &copy;&nbsp;{new Date().getFullYear()} General Intelligence. All rights
        reserved.
      </>
    )}
  </Row>
)
