import {ErrorState, LoadingState, Row} from '@gicortex/nucleus'

interface TransitionStateProps {
  children: React.ReactNode
  error: unknown
  isLoading: boolean
}

export const TransitionState = ({
  children,
  error,
  isLoading,
}: TransitionStateProps) => {
  if (isLoading) {
    return (
      <Row className='justify-center p-2'>
        <LoadingState />
      </Row>
    )
  }

  if (error) {
    return (
      <Row className='justify-center p-2'>
        <ErrorState />
      </Row>
    )
  }

  return children
}
