import {useCreateNewPromptMutation} from '@/api'
import {useRequest} from '@/hooks/request'
import type {Folder} from '@/types'
import {
  Form,
  FormModal,
  nonEmptyString,
  optionalString,
} from '@gicortex/nucleus'

export interface CreatePromptModalProps {
  folder?: Folder
  pathPrefix: string
}
export const CreatePromptModal = ({
  folder,
  pathPrefix,
}: CreatePromptModalProps) => {
  const [createPrompt] = useCreateNewPromptMutation()
  const {handleMutation} = useRequest()

  const handleSubmit = ({
    name,
    description,
    prompt,
  }: {
    name: string
    description: string
    prompt: string
  }) => {
    return handleMutation({
      notficationProps: {
        entityName: 'prompt',
        action: 'create',
      },
      operation: createPrompt({
        createPromptModel: {
          folderId: folder?.id,
          name,
          description,
          prompt,
        },
      }),
    })
  }

  return (
    <FormModal
      modalProps={{size: 'm'}}
      title='Create Prompt'
      onSubmit={handleSubmit}
      defaultValues={{
        name: '',
        description: '',
        prompt: '',
      }}
      schema={() => ({
        name: nonEmptyString('Name'),
        description: optionalString(),
        prompt: nonEmptyString('Prompt'),
      })}
      submitButtonProps={{
        label: 'Create',
      }}
    >
      <Form.Input
        disabled
        label='Folder'
        name='folder'
        value={`/${pathPrefix}${folder?.path ? folder.path : ''}`}
      />
      <Form.Input
        autoFocus
        name='name'
        label='Name'
        type='text'
      />
      <Form.Textarea
        name='description'
        label='Description'
        rows={3}
      />
      <Form.Textarea
        label='Prompt'
        name='prompt'
        rows={10}
      />
    </FormModal>
  )
}
