import {createContext, useCallback, useState} from 'react'

interface PromptContextArgs {
  prompt: string
  setPrompt: (prompt: string) => void
  resetPrompt: () => void
}

export const PromptContext = createContext<PromptContextArgs>({
  prompt: '',
  setPrompt: () => {},
  resetPrompt: () => {},
})

export const PromptProvider = ({children}: {children: React.ReactNode}) => {
  const [content, setContent] = useState('')

  const setPrompt = useCallback((prompt: string) => {
    setContent(prompt)
  }, [])

  const resetPrompt = useCallback(() => {
    setContent('')
  }, [])

  return (
    <PromptContext.Provider
      value={{
        prompt: content,
        setPrompt,
        resetPrompt,
      }}
    >
      {children}
    </PromptContext.Provider>
  )
}
