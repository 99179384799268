const arrayIndex = <T extends unknown[]>(array: T): number => {
  return Math.floor(Math.random() * array.length)
}

const uuid = (): string => {
  return crypto.randomUUID()
}

export const randomUtil = {
  arrayIndex,
  uuid,
}
