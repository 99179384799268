import {
  type GetDatabucketFilesApiResponse,
  type GetFoldersApiResponse,
  useGetDatabucketFilesQuery,
  useGetFoldersQuery,
  useGetUsersMeQuery,
} from '@/api'
import {CreateFolderButton} from '@/components/create-folder-button'
import {TransitionState} from '@/components/transition-state'
import {Tree} from '@/components/tree'
import {FolderType} from '@/configs'
import {SidebarPanel} from '@/layout/sidebar-panel'
import type {Folder} from '@/types'
import {folderUtil} from '@/utils'
import {Col} from '@gicortex/nucleus'
import {useEffect, useState} from 'react'
import {CreateFilesFolderModal} from './create-files-folder-modal'
import {UploadFileButton} from './upload-file-button'

const TITLE = 'Your Files'

const ROOT_FOLDER: Folder = {
  children: [],
  name: 'Your Files',
  path: '/Your Files',
}
const YourFilesTitle = () => {
  const [rootFolder, setRootFolder] = useState<Folder>()

  const {data: me} = useGetUsersMeQuery()

  useEffect(() => {
    if (!rootFolder) {
      setRootFolder({id: me?.id, groupId: me?.id, ...ROOT_FOLDER})
    }
  }, [rootFolder, me])

  if (!rootFolder) {
    return null
  }
  return (
    <SidebarPanel.Title title={ROOT_FOLDER.name}>
      <UploadFileButton
        folder={rootFolder}
        pathPrefix={TITLE}
      />
      <CreateFolderButton
        modal={
          <CreateFilesFolderModal
            folder={rootFolder}
            pathPrefix={TITLE}
          />
        }
      />
    </SidebarPanel.Title>
  )
}
export interface YourFilesProps {
  folders?: GetFoldersApiResponse
  files?: GetDatabucketFilesApiResponse
}
export const YourFiles = ({folders, files}: YourFilesProps) => {
  const [rootFolderTree, setRootFolderTree] = useState<Folder>()

  const {error: foldersError, isLoading: foldersIsLoading} = useGetFoldersQuery(
    {type: FolderType.FILES},
  )
  const {error: filesError, isLoading: filesIsLoading} =
    useGetDatabucketFilesQuery()
  const {data: me} = useGetUsersMeQuery()

  useEffect(() => {
    if (folders && files) {
      const tree = folderUtil.toTree({
        rootFolderName: ROOT_FOLDER.name,
        folderResponses: folders.filter((folder) => folder.groupId === me?.id),
        responseEntities: files.filter((file) => {
          const parts = file.file.split('/')
          return parts[1] === 'Personal'
        }),
      })
      tree.id = me?.id
      tree.groupId = me?.id
      setRootFolderTree(tree)
    }
  }, [folders, files, me])

  return (
    <Col>
      <TransitionState
        error={foldersError || filesError}
        isLoading={foldersIsLoading || filesIsLoading}
      >
        {rootFolderTree && (
          <Tree
            folder={rootFolderTree}
            pathPrefix={TITLE}
          />
        )}
      </TransitionState>
    </Col>
  )
}

YourFiles.Title = YourFilesTitle
