import type {HttpError} from '@/types'
import {createNotficationMessage} from '@/utils/notification-message'
import type {CraftNotificationParams} from '@/utils/notification-message'
import {useToast} from '@gicortex/nucleus'
import type {
  BaseQueryFn,
  MutationActionCreatorResult,
  MutationDefinition,
} from '@reduxjs/toolkit/query'
import {useCallback} from 'react'

export interface MutationHandlerProps<Response> {
  hideNotification?: boolean
  notficationProps?: CraftNotificationParams | undefined
  onComplete?: () => void
  onError?: (error: HttpError) => void
  onSuccess?: () => void
  operation: MutationAction<Response>
}

export type MutationAction<Response> = MutationActionCreatorResult<
  MutationDefinition<unknown, BaseQueryFn, string, Response, string>
>

export const useHandleMutation = () => {
  const {showSuccessToast, showErrorToast} = useToast()

  const handleMutation = useCallback(
    async <Response>({
      hideNotification = false,
      notficationProps,
      onComplete,
      onError,
      onSuccess,
      operation,
    }: MutationHandlerProps<Response>): Promise<Response> => {
      let result: Response | undefined = undefined
      try {
        result = await operation.unwrap()
        onSuccess?.()

        if (!hideNotification) {
          const {successMessage} = createNotficationMessage(notficationProps)
          showSuccessToast(successMessage)
        }
      } catch (error: unknown) {
        const httpError = error as HttpError
        onError?.(httpError)
        if (!hideNotification) {
          if (httpError.status === 400 || httpError.status === 409) {
            showErrorToast(httpError.data.detail)
          } else {
            const {errorMessage} = createNotficationMessage(notficationProps)
            showErrorToast(notficationProps?.customErrorMessage ?? errorMessage)
          }
        }
      } finally {
        onComplete?.()
      }
      return result as Response
    },
    [showSuccessToast, showErrorToast],
  )
  return {handleMutation}
}
