import type {PromptResponse} from '@/api'
import {APP_STATE} from '@/configs'
import {useCurrentPrompt} from '@/hooks/current-prompt'
import type {RootState} from '@/types'
import {IconButton, cn} from '@gicortex/nucleus'
import {ArrowUp} from 'lucide-react'
import {useSelector} from 'react-redux'

export function focusPrompt() {
  const element = document.querySelector(
    '[data-prompt-input]',
  ) as HTMLTextAreaElement
  if (element) {
    element.focus()
  }
}

export interface SendButtonProps {
  disabled?: boolean
  prompt?: PromptResponse | string
  className?: string
}
export const SendButton = ({
  disabled,
  className,
  prompt: propPrompt,
}: SendButtonProps) => {
  const {sendPrompt, prompt: contextPrompt} = useCurrentPrompt()
  const finalPrompt = propPrompt || contextPrompt
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    sendPrompt({prompt: finalPrompt})
  }

  return (
    <IconButton
      className={cn(
        'disabled:bg-red-100 disabled:cursor-not-allowed rounded-full',
        className,
      )}
      disabled={disabled}
      icon={ArrowUp}
      onClick={handleClick}
      tooltip={
        disabled && String(finalPrompt).length > 0
          ? 'Please wait until all files finish processing'
          : 'Send Prompt'
      }
    />
  )
}

export interface SendPromptButtonProps {
  disabled: boolean
  prompt?: string
}
export const SendPromptButton = ({disabled, prompt}: SendPromptButtonProps) => {
  const current = useSelector((state: RootState) => state.current)
  const pendingFilesCheck = current.selectedFiles.some(
    (file) => file.status !== 'SUCCESS',
  )
  const isSendButtonDisabled =
    pendingFilesCheck ||
    disabled ||
    current.appState === APP_STATE.WAITING_FOR_AGENT_RESPONSE
  return (
    <div className='rounded-r-full'>
      <SendButton
        className='enabled:bg-green-200'
        prompt={prompt}
        disabled={isSendButtonDisabled}
      />
    </div>
  )
}
