import {
  type ChatResponse,
  type ResponseMessage,
  api,
  useCreateNewChatInputMutation,
} from '@/api'
import {APP_STATE, ROLE, SUGGESTION_GENRE, TAG_TYPE} from '@/configs'
import {currentSlice} from '@/store/current'
import type {RootState, Suggestion} from '@/types'
import {randomUtil} from '@/utils'
import {Button, Col, Icon, KvLogo, PlaceCenter} from '@gicortex/nucleus'
import {GraduationCap, Laugh, Lightbulb, PenLine} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'

const GENRE_COLOR = {
  [SUGGESTION_GENRE.EDUCATION]: 'text-blue-400',
  [SUGGESTION_GENRE.FUN]: 'text-yellow-400',
  [SUGGESTION_GENRE.IDEATION]: 'text-green-400',
  [SUGGESTION_GENRE.WORK]: 'text-red-400',
} as const

const GENRE_ICON = {
  [SUGGESTION_GENRE.EDUCATION]: GraduationCap,
  [SUGGESTION_GENRE.FUN]: Laugh,
  [SUGGESTION_GENRE.IDEATION]: Lightbulb,
  [SUGGESTION_GENRE.WORK]: PenLine,
} as const

const SUGGESTIONS: Suggestion[] = [
  {
    label: 'Quiz me on world capitals',
    prompt:
      "Let's start improving my geography skills by quizzing me on world capitals. You can start by asking me the capital of a country of your choice, and I'll do my best to answer correctly.",
    genre: SUGGESTION_GENRE.EDUCATION,
  },
  {
    label: 'Thank my interviewer',
    prompt:
      "Write 2-3 sentences to thank my interviewer, reiterating my excitement for the job opportunity while keeping it cool. Don't make it too formal.",
    genre: SUGGESTION_GENRE.IDEATION,
  },
  {
    label: 'Overcome procrastination',
    prompt:
      'Could you start by asking me about times when I procrastinate the most and then give me some suggestions to overcome it?',
    genre: SUGGESTION_GENRE.WORK,
  },
  {
    label: 'Tell me a joke',
    prompt: 'I could use a good laugh. Can you tell me a joke?',
    genre: SUGGESTION_GENRE.FUN,
  },
] as const

export const Suggestions = () => {
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()
  const [sendPrompt] = useCreateNewChatInputMutation()

  const handleClick = async (prompt: string) => {
    try {
      // add the message to the chat
      const userMessage: Partial<ChatResponse> = {
        id: randomUtil.uuid(),
        source: ROLE.USER,
        message: prompt,
        files: [],
        createdAt: Date(),
        updatedAt: Date(),
      }
      dispatch(currentSlice.actions.addMessage(userMessage))
      // show the loading indicator
      dispatch(
        currentSlice.actions.setAppState(APP_STATE.WAITING_FOR_AGENT_RESPONSE),
      )
      // send the message to the server
      const response = await sendPrompt({
        createChatRequest: {
          input: prompt,
          historyId: current.historyId,
          modelType: 'fast',
        },
      }).unwrap()
      dispatch(api.util.invalidateTags([TAG_TYPE.CHAT_HISTORY]))
      // add the response to the chat
      const agentMessage: ResponseMessage = {
        historyId: response.historyId,
        // @ts-ignore
        from: response.from,
        id: response.id,
        prompt,
        answer: response.message,
      }
      dispatch(
        currentSlice.actions.addMessage({
          ...agentMessage,
          source: ROLE.AGENT,
        }),
      )
      if (!current.historyId) {
        dispatch(currentSlice.actions.setHistoryId(response.historyId))
      }
    } catch (error) {
      console.error(error)
      // show error toast
    } finally {
      // hide the loading indicator
      dispatch(
        currentSlice.actions.setAppState(APP_STATE.WAITING_FOR_USER_INPUT),
      )
    }
  }

  return (
    <div className='h-full max-w-screen-xl m-auto'>
      <PlaceCenter>
        <Col className='gap-8 items-center p-8'>
          <KvLogo />
          <div className='auto-rows-max grid grid-flow-rows grid-cols-2 lg:grid-cols-4 gap-8'>
            {SUGGESTIONS.map((suggestion) => (
              <Button
                className='bg-gray-50 p-4 text-left'
                onClick={() => handleClick(suggestion.prompt)}
                key={btoa(suggestion.label)}
              >
                <Col className='gap-4 h-full'>
                  <Icon
                    className={GENRE_COLOR[suggestion.genre]}
                    component={GENRE_ICON[suggestion.genre]}
                  />
                  <p className='text-ellipsis overflow-hidden'>
                    {suggestion.label}
                  </p>
                </Col>
              </Button>
            ))}
          </div>
        </Col>
      </PlaceCenter>
    </div>
  )
}
