import {useDeleteFolderMutation} from '@/api'
import {useRequest} from '@/hooks/request'
import type {Folder} from '@/types'
import {DeleteModalButton} from '@gicortex/nucleus'

export interface DeleteFolderButtonProps {
  folder: Folder
}
export const DeleteFolderButton = ({folder}: DeleteFolderButtonProps) => {
  const [deleteFolder] = useDeleteFolderMutation()
  const {handleMutation} = useRequest()
  const handleDelete = async () => {
    if (!folder.id) {
      throw new Error('Folder ID is missing')
    }
    return handleMutation({
      notficationProps: {
        entityName: 'folder',
        action: 'delete',
      },
      operation: deleteFolder({folderId: folder.id}),
    })
  }

  return (
    <DeleteModalButton
      className='p-0 hidden group-hover:block'
      content={
        <p>
          Are you sure you want to delete folder <strong>{folder.path}</strong>?
        </p>
      }
      title='Delete Folder'
      tooltip='Delete Folder'
      onSubmit={handleDelete}
      submitButtonProps={{
        label: 'Delete',
      }}
    />
  )
}
