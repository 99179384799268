import {LoadingState, Row, cn} from '@gicortex/nucleus'
import PDFObject from 'pdfobject'
import {useEffect, useState} from 'react'

export interface PdfViewerProps {
  className?: string
  id?: string
  url: string
}

export const PdfViewer = ({
  className,
  id = 'pdf-viewer',
  url,
}: PdfViewerProps) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const pdf_iframe = PDFObject.embed(url, `#${id}`, {forcePDFJS: true})
    pdf_iframe.addEventListener('load', () => {
      setIsLoading(false)
    })
  }, [id, url])

  return (
    <>
      <div
        id={id}
        className={cn('h-full w-full', isLoading && 'hidden', className)}
      />
      <Row
        className={cn(
          'items-center justify-center h-full',
          !isLoading && 'hidden',
        )}
      >
        <LoadingState />
      </Row>
    </>
  )
}
