const pluralize = (
  word: string,
  options?: {
    suffix?: string
    slice?: number
    itemCount?: number
    condition?: boolean
  },
) => {
  if (
    options?.condition === false ||
    (options?.itemCount && options.itemCount < 2)
  ) {
    return word
  }

  const suffix = options?.suffix ?? 's'
  const sliced = options?.slice ? word.slice(0, options.slice) : word

  return `${sliced}${suffix}`
}

export const textUtil = {
  pluralize,
}
