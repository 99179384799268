import {useDownloadDatabucketFileMutation} from '@/api'
import {FileType} from '@/configs'
import {layoutSlice} from '@/store/layout'
import type {CsvTab, DataBucketFile, PdfTab} from '@/types'
import {fileUtil} from '@/utils'
import {Button, Icon} from '@gicortex/nucleus'
import {Eye} from 'lucide-react'
import {useDispatch} from 'react-redux'

export interface PreviewButtonProps {
  file: DataBucketFile
}

export const PreviewButton = ({file}: PreviewButtonProps) => {
  const dispatch = useDispatch()

  const [getDownloadUrl] = useDownloadDatabucketFileMutation()

  const handleClick = async () => {
    const result = await getDownloadUrl({
      databucketName: file.bucket,
      dataBucketRequest: {path: fileUtil.getSourceId(file)},
    }).unwrap()
    const tab: PdfTab | CsvTab = {
      label: fileUtil.getName(file),
      type:
        fileUtil.getExtension(file) === FileType.CSV
          ? FileType.CSV
          : FileType.PDF,
      url: result.url,
    }
    dispatch(layoutSlice.actions.openInSplitPane(tab))
  }

  return (
    <Button
      className='p-0 hidden group-hover:block'
      onClick={handleClick}
      tooltip='Preview'
    >
      <Icon component={Eye} />
    </Button>
  )
}
