import {ButtonGroup} from '@/components/button-group'
import {
  Col,
  DisclosureStack,
  type DisclosureStackProps,
  cn,
} from '@gicortex/nucleus'
import {Row} from '@gicortex/nucleus'

export interface SidebarPanelTitleProps {
  title: string
  children?: React.ReactNode
}

const SidebarPanelTitle = ({children, title}: SidebarPanelTitleProps) => {
  return (
    <Row className='justify-between w-full'>
      <h6 className='text-black text-sm font-semibold'>{title}</h6>
      <div
        // Avoid bubbling events to the parent
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
      >
        <ButtonGroup className='pr-2 space-x-2 [&_button]:p-0'>
          {children}
        </ButtonGroup>
      </div>
    </Row>
  )
}

export interface SidebarPanelProps extends DisclosureStackProps {}

export const SidebarPanel = ({...props}: SidebarPanelProps) => {
  return (
    <Col className={cn('h-[calc(100vh-171px)]')}>
      <DisclosureStack {...props} />
    </Col>
  )
}

SidebarPanel.Title = SidebarPanelTitle
