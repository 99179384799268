import {authConfig} from '@/configs'

const setTokens = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string
  refreshToken: string
}): void => {
  sessionStorage.setItem(authConfig.accessTokenCacheKey, accessToken)
  sessionStorage.setItem(authConfig.refreshTokenCacheKey, refreshToken)
}

const getTokens = () => {
  const accessToken = sessionStorage.getItem(authConfig.accessTokenCacheKey)
  const refreshToken = sessionStorage.getItem(authConfig.refreshTokenCacheKey)
  return {accessToken, refreshToken}
}

const removeTokens = () => {
  sessionStorage.removeItem(authConfig.accessTokenCacheKey)
  sessionStorage.removeItem(authConfig.refreshTokenCacheKey)
}

export const authUtil = {
  getTokens,
  removeTokens,
  setTokens,
}
