import {useCreateDatabucketFileIndexMutation} from '@/api'
import type {AppDispatch, DataBucketFile} from '@/types'
import {RefreshIconButton} from '@gicortex/nucleus'
import {useDispatch} from 'react-redux'

export interface ReprocessFileButtonProps {
  file: DataBucketFile
}
import {retryFile} from '@/store/files'

export const ReprocessFileButton = ({file}: ReprocessFileButtonProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [_, {isLoading}] = useCreateDatabucketFileIndexMutation()

  const handleRefetch = async () => {
    dispatch(
      retryFile({
        databucketName: file.bucket,
        dataBucketFileIndexRequest: {path: file.path},
      }),
    )
  }

  return (
    <RefreshIconButton
      isFetching={isLoading}
      refetch={handleRefetch}
      tooltip='Reprocess'
      className='p-0 hidden group-hover:block'
    />
  )
}
