import type {Message} from '@/api'
import {APP_STATE, SidebarTab} from '@/configs'
import type {AppState, DataBucketFile} from '@/types'
import {createSlice} from '@reduxjs/toolkit'

const initialState: {
  appState: AppState
  historyId?: string
  messages: Message[]
  selectedFiles: DataBucketFile[]
  selectedSidebarTab: SidebarTab
  uploadingFiles: (DataBucketFile & {id: string})[]
  uploadingGroup: {id?: string; name?: string}
} = {
  appState: APP_STATE.WAITING_FOR_USER_INPUT,
  historyId: undefined,
  messages: [],
  selectedFiles: [],
  selectedSidebarTab: SidebarTab.HISTORY,
  uploadingFiles: [],
  uploadingGroup: {},
}

export const currentSlice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload)
    },
    reset: () => initialState,
    removeFile: (state, action) => {
      state.selectedFiles = state.selectedFiles.filter(
        (file) => file.path !== action.payload.path,
      )
    },
    selectFile: (state, action) => {
      const file = action.payload
      if (!state.selectedFiles.find((f) => f.path === file.path)) {
        state.selectedFiles.push(file)
      }
    },
    clearSelectedFiles(state) {
      state.selectedFiles = []
    },
    setAppState: (state, action) => {
      state.appState = action.payload
    },
    setHistoryId: (state, action) => {
      state.historyId = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setSelectedSidebarTab: (state, action) => {
      state.selectedSidebarTab = action.payload
    },
    setUploadingFiles: (state, action) => {
      state.uploadingFiles = action.payload
    },
    setUploadingGroup: (state, action) => {
      state.uploadingGroup = action.payload
    },
    removeUploadingFiles: (state, action) => {
      state.uploadingFiles = state.uploadingFiles.filter(
        (file) => !action.payload.includes(file.id),
      )
    },
  },
})
