import {useDeleteDatabucketFileMutation} from '@/api'
import {filesSlice} from '@/store/files'
import type {DataBucketFile, HttpError} from '@/types'
import {fileUtil} from '@/utils'
import {DeleteModalButton, useToast} from '@gicortex/nucleus'
import React from 'react'

const ACTION_TITLE = 'Delete File'

import {useDispatch} from 'react-redux'
export interface DeleteFileButtonProps {
  file: DataBucketFile
}

export const DeleteFileButton = ({file}: DeleteFileButtonProps) => {
  const [, setSubmitting] = React.useState(false)

  const {showSuccessToast, showErrorToast} = useToast()

  const [deleteItem] = useDeleteDatabucketFileMutation()
  const dispatch = useDispatch()

  const handleDelete = async (item: DataBucketFile) => {
    try {
      setSubmitting(true)
      await deleteItem({
        databucketName: item.bucket,
        dataBucketRequest: {path: item.path},
      }).unwrap()
      dispatch(filesSlice.actions.removeFile({filePath: item.path}))
      showSuccessToast('File deleted successfully')
    } catch (error) {
      const httpError = error as HttpError
      switch (httpError.status) {
        default:
          showErrorToast('Failed to delete file')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <DeleteModalButton
      className='p-0 hidden group-hover:block'
      title={ACTION_TITLE}
      tooltip={ACTION_TITLE}
      content={
        <p>
          Are you sure you want to delete{' '}
          <strong>{fileUtil.getFullPath(file) + fileUtil.getName(file)}</strong>
          ?
        </p>
      }
      onSubmit={() => handleDelete(file)}
    />
  )
}
