import type {FolderResponseModel} from '../api'
export {authConfig} from './auth'

export const API_URL = import.meta.env.VITE_API_URL

export const APP_STATE = {
  WAITING_FOR_AGENT_RESPONSE: 'WAITING_FOR_AGENT_RESPONSE',
  WAITING_FOR_USER_INPUT: 'WAITING_FOR_USER_INPUT',
} as const

export const CODE_LANGUAGE_SUBSET = [
  'python',
  'javascript',
  'java',
  'go',
  'bash',
  'c',
  'cpp',
  'csharp',
  'css',
  'diff',
  'graphql',
  'json',
  'kotlin',
  'less',
  'lua',
  'makefile',
  'markdown',
  'objectivec',
  'perl',
  'php',
  'php-template',
  'plaintext',
  'python-repl',
  'r',
  'ruby',
  'rust',
  'scss',
  'shell',
  'sql',
  'swift',
  'typescript',
  'vbnet',
  'wasm',
  'xml',
  'yaml',
]

export const RESPONSE_TYPE = {
  ERROR: 'error',
} as const

export const ROLE = {
  Assistant: 'assistant',
  System: 'system',
  User: 'user',
  // Deprecated
  AGENT: 'ai',
  USER: 'user',
} as const

export enum SidebarTab {
  HISTORY = 'History',
  FILES = 'Files',
  PROMPTS = 'Prompts',
}

export const SUGGESTION_GENRE = {
  EDUCATION: 'EDUCATION',
  FUN: 'FUN',
  IDEATION: 'IDEATION',
  WORK: 'WORK',
} as const

export const TAG_TYPE = {
  CHAT_HISTORY: 'CHAT_HISTORY',
  DATA_BUCKET: 'DATA_BUCKET',
  FILE: 'FILE',
  FOLDER: 'FOLDER',
  PROMPT: 'PROMPT',
} as const

export const STORAGE_PROTOCOL = {
  AWS: 's3://',
} as const

export const ROOT_FOLDER = {
  id: 'Your Prompts/',
  path: '/',
} as FolderResponseModel

export enum FileProcessingStatus {
  FAILURE = 'FAILURE',
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export enum FileType {
  CSV = 'csv',
  PDF = 'pdf',
}

export enum FolderType {
  FILES = 'vault',
  PROMPTS = 'prompt',
}

export const FILE_PROCESSING = {
  polling: {
    interval: 10000,
    maxAttempts: 6,
  },
  status: {
    success: 'SUCCESS',
    pending: 'PENDING',
    failure: 'FAILURE',
  },
  color: {
    success: 'text-green-500',
    pending: 'animate-pulse text-yellow-500',
    failure: 'text-red-500',
  },
  description: {
    success: 'Ready',
    pending: 'Processing',
    failure: 'Failure',
  },
} as const
