import {api} from '@/api'
import {authUtil} from '@/utils'
import {Layout, LoadingState, Row} from '@gicortex/nucleus'
import {useEffect} from 'react'

export const LogoutPage = () => {
  const {data: config} = api.useGetConfigsQuery()

  useEffect(() => {
    if (config) {
      authUtil.removeTokens()
      window.location.href = `${config.logoutUrl}?origin=${window.location.origin}`
    }
  }, [config])

  return (
    <Layout>
      <Row className='items-center justify-center h-full'>
        <LoadingState />
      </Row>
    </Layout>
  )
}
