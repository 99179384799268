import {FileFolder} from '@/features/files/file-folder'
import {FileItem} from '@/features/files/file-item'
import {PromptFolder} from '@/features/prompts/prompt-folder'
import {PromptItem} from '@/features/prompts/prompt-item'
import type {DataBucketFile, Entity, Folder, Prompt} from '@/types'
import {folderUtil, randomUtil} from '@/utils'
import {Col} from '@gicortex/nucleus'

export interface TreeProps {
  folder?: Folder
  pathPrefix: string
}
export const Tree = ({folder, pathPrefix}: TreeProps) => {
  if (!folder) {
    return null
  }

  return (
    <Col>
      {folder.children.map((item: Folder | Entity) => {
        if (folderUtil.isFileFolder(item)) {
          const fileFolder = item as Folder
          fileFolder.id ? fileFolder.id : randomUtil.uuid()
          return (
            <FileFolder
              folder={fileFolder}
              key={fileFolder.id}
              pathPrefix={pathPrefix}
            />
          )
        }
        if (folderUtil.isFileItem(item)) {
          const fileItem = item as unknown as DataBucketFile
          return (
            <FileItem
              file={fileItem}
              key={fileItem.path}
              pathPrefix={pathPrefix}
            />
          )
        }
        if (folderUtil.isPromptFolder(item)) {
          return (
            <PromptFolder
              folder={item as Folder}
              key={item.id}
              pathPrefix={pathPrefix}
            />
          )
        }
        if (folderUtil.isPromptItem(item)) {
          const promptItem = item as unknown as Prompt
          return (
            <PromptItem
              key={promptItem.id}
              pathPrefix={pathPrefix}
              prompt={promptItem}
            />
          )
        }
      })}
    </Col>
  )
}
