import {api, useGetUsersMeQuery} from '@/api'
import {Chat} from '@/features/chat'
import {Layout} from '@/layout'
import {historySlice} from '@/store/history'
import {ErrorState, LoadingState, PlaceCenter} from '@gicortex/nucleus'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

export const AppPage = () => {
  // load me to cache user info on app init
  const {isLoading: meIsLoading, error: meError} = useGetUsersMeQuery()
  const {data: chats, isLoading, error} = api.useGetChatHistoriesQuery()
  const dispatch = useDispatch()

  useEffect(() => {
    if (chats) {
      dispatch(historySlice.actions.setChats(chats))
    }
  })

  if (isLoading || meIsLoading) {
    return (
      <PlaceCenter>
        <LoadingState />
      </PlaceCenter>
    )
  }

  if (error || meError) {
    return (
      <PlaceCenter>
        <ErrorState />
      </PlaceCenter>
    )
  }

  return (
    <Layout>
      <Chat />
    </Layout>
  )
}
