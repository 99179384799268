import {
  useGetFoldersQuery,
  useGetUserGroupsQuery,
  useGetUsersMeQuery,
} from '@/api'
import {FolderType} from '@/configs'
import {IconButton, useModal} from '@gicortex/nucleus'
import {FolderInput} from 'lucide-react'

export interface MoveToFolderButtonProps {
  folderType: FolderType
  modal: React.ReactNode
  pathPrefix: string
}

export const MoveToFolderButton = ({
  folderType,
  modal,
  pathPrefix,
}: MoveToFolderButtonProps) => {
  const {openModal} = useModal()

  const {data: folders} = useGetFoldersQuery({type: folderType})
  const {data: me} = useGetUsersMeQuery()
  const {data: userGroups} = useGetUserGroupsQuery()

  const handleClick = () => {
    openModal(modal)
  }

  if (folderType === FolderType.FILES) {
    const isYourFile = pathPrefix === 'Your Files'
    const yourFilesHasNoFolders =
      folders?.filter((folder) => folder.groupId === me?.id).length === 0
    const sharedFolderHasNoFolders =
      folders?.filter((folder) => {
        const fileGroupId = userGroups?.find(
          (userGroup) => userGroup.name === pathPrefix,
        )?.id
        return folder.groupId === fileGroupId
      }).length === 0
    if (isYourFile && yourFilesHasNoFolders) {
      return null
    }
    if (!isYourFile && sharedFolderHasNoFolders) {
      return null
    }
  }
  if (folderType === FolderType.PROMPTS) {
    const isYourPrompt = pathPrefix === 'Your Prompts'
    const yourPromptsHasNoFolders =
      folders?.filter((folder) => folder.groupId === me?.id).length === 0
    if (isYourPrompt && yourPromptsHasNoFolders) {
      return null
    }
  }

  return (
    <IconButton
      icon={FolderInput}
      tooltip='Move to Folder'
      onClick={handleClick}
    />
  )
}
