export interface NotificationMessages {
  successMessage: string
  errorMessage: string
}

export interface CustomMessagesOnly {
  action?: never
  entityName?: never
  customSuccessMessage?: string
  customErrorMessage?: string
}

export interface ActionEntityParams {
  action: Action
  entityName: EntityName
  customSuccessMessage?: string
  customErrorMessage?: string
}

export type CraftNotificationParams = CustomMessagesOnly | ActionEntityParams

export type EntityName =
  | 'chat history'
  | 'data bucket'
  | 'file'
  | 'folder'
  | 'message'
  | 'prompt'

export const ACTION = {
  approve: 'approved',
  archive: 'archived',
  create: 'created',
  delete: 'deleted',
  download: 'downloaded',
  fetch: 'fetched',
  get: 'fetched',
  refresh: 'refreshed',
  sync: 'synced',
  update: 'updated',
  upload: 'uploaded',
} as const

export type Action = keyof typeof ACTION

const DEFAULT_MESSAGES = {
  SUCCESS: 'Operation completed successfully',
  ERROR: 'Operation failed',
} as const

export function createNotficationMessage(
  {
    action,
    entityName,
    customErrorMessage,
    customSuccessMessage,
  } = {} as CraftNotificationParams,
): NotificationMessages {
  if (customSuccessMessage && customErrorMessage) {
    return {
      successMessage: customSuccessMessage,
      errorMessage: customErrorMessage,
    }
  }

  if (!action || !entityName) {
    if (customSuccessMessage || customErrorMessage) {
      return {
        successMessage: customSuccessMessage ?? DEFAULT_MESSAGES.SUCCESS,
        errorMessage: customErrorMessage ?? DEFAULT_MESSAGES.ERROR,
      }
    }
    throw new Error(
      'Please provide either custom messages or both action and entity name.',
    )
  }
  const pastAction = ACTION[action]
  return {
    successMessage:
      customSuccessMessage ?? `Successfully ${pastAction} ${entityName}`.trim(),
    errorMessage:
      customErrorMessage ?? `Failed to ${action} ${entityName}`.trim(),
  }
}
