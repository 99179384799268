import {SidebarPanel} from '@/layout/sidebar-panel'
import {YourPrompts} from './your-prompts'

export const Prompts = () => {
  return (
    <SidebarPanel
      panels={[
        {
          key: 'your-prompts',
          title: <YourPrompts.Title />,
          body: <YourPrompts />,
        },
      ]}
    />
  )
}
