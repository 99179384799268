import {SidebarTab} from '@/configs'
import {NewChatButton} from '@/features/chat/new-chat-button'
import {Files} from '@/features/files'
import {FilesIcon} from '@/features/files/icon'
import {History} from '@/features/history'
import {HistoryIcon} from '@/features/history/icon'
import {Prompts} from '@/features/prompts'
import {PromptsIcon} from '@/features/prompts/icon'
import {SplitPane} from '@/layout/split-pane'
import {PromptProvider} from '@/providers/prompt'
import {currentSlice} from '@/store/current'
import {layoutSlice} from '@/store/layout'
import type {RootState} from '@/types'
import {
  KvLogo,
  Layout as NucleusLayout,
  type SidebarTab as SidebarTabType,
  TabPanel,
  TabPanels,
} from '@gicortex/nucleus'
import type React from 'react'
import {useDispatch, useSelector} from 'react-redux'

const LayoutHeader = () => {
  return (
    <NucleusLayout.Header>
      <NewChatButton />
    </NucleusLayout.Header>
  )
}

export const Sidebar = ({tabs}: {tabs: SidebarTabType[]}) => {
  return (
    <TabPanels className='overflow-y-auto mt-0'>
      {tabs.map((tab) => (
        <TabPanel
          className='!py-0'
          key={tab.id}
        >
          {tab.panel}
        </TabPanel>
      ))}
    </TabPanels>
  )
}

interface LayoutProps {
  center?: boolean
  children: React.ReactNode
}

export const Layout = ({center, children}: LayoutProps) => {
  const layout = useSelector((state: RootState) => state.layout)
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()

  const tabs = [
    {
      id: 1,
      icon: HistoryIcon,
      label: SidebarTab.HISTORY,
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SidebarTab.HISTORY,
        )
        dispatch(action)
      },
      panel: <History />,
      selected: current.selectedSidebarTab === SidebarTab.HISTORY,
    },
    {
      id: 2,
      icon: FilesIcon,
      label: SidebarTab.FILES,
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SidebarTab.FILES,
        )
        dispatch(action)
      },
      panel: <Files />,
      selected: current.selectedSidebarTab === SidebarTab.FILES,
    },
    {
      id: 3,
      icon: PromptsIcon,
      label: SidebarTab.PROMPTS,
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SidebarTab.PROMPTS,
        )
        dispatch(action)
      },
      panel: <Prompts />,
      selected: current.selectedSidebarTab === SidebarTab.PROMPTS,
    },
  ]

  return (
    <PromptProvider>
      <NucleusLayout
        appLogo={<KvLogo size='m' />}
        center={center}
        showSidebar={layout.showSidebar}
        showEmptyState={false}
        onClickToggleSidebar={() => {
          dispatch(layoutSlice.actions.toggleSidebar())
        }}
        sidebarTabs={tabs}
        sidebar={<Sidebar tabs={tabs} />}
        header={<LayoutHeader />}
        splitPane={layout.splitPaneTabs.length > 0 && <SplitPane />}
      >
        {children}
      </NucleusLayout>
    </PromptProvider>
  )
}
